//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../../css/Sonoco.css';
import banner4 from '../../img/banner4.jfif'
import banner from '../../img/banners/25.jfif'
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-bootstrap';
import slider1 from '../../img/slider/1.jfif'
import alu1 from '../../img/products/alu1.jpg'
import alu2 from '../../img/products/alu2.jpg'
import alu3 from '../../img/products/alu3.jpg'
import alu4 from '../../img/products/alu4.jpg'
import alu5 from '../../img/products/alu5.jpg'
import alu6 from '../../img/products/alu6.jpg'
import alu7 from '../../img/products/alu7.jpg'
import slider3 from '../../img/slider/3.jfif'
import slider4 from '../../img/slider/4.jfif'
import slider5 from '../../img/slider/6.jfif'
export default function Aluminium() {
  
    const [type, settype] = useState('alutube')
function settabtype(val){
    settype(val)
}

const [type2, settype2] = useState('product')
function settabtype2(val){
    settype2(val)
}
  return (

    <section id="cd-intro">
<Helmet>
        <title>MenaPac | Aluminium</title>
      </Helmet>
        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'bottom' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="bannertitle" >Aluminium Tubes & Foil</h1>
                            {/* <p>Search for answers or explore regular help topics below</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="container help mt-5 maincontent">
          <div className="row">
          <div className="col-md-5 n-slider" style={{paddingLeft:'0px'}}>
                <Carousel>
   
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={alu3}
        alt="Second slide"
        />
        <Carousel.Caption>
        
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={alu2}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={alu4}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={alu5}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={alu1}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
</Carousel>
              
                </div>
                <div className="col-md-7" style={{marginTop:'9%'}}>
                <p>a cylinder of pliable pure aluminum (99.7% purity) which can be sealed in such a manner that its contents (ointment, gel, cream etc.), although readily discharged in any desired quantity, are protected from contact with air or moisture during the whole period of use as a container.</p>
      
                </div>
          </div>
       
      <div className='row'>
                
              
 <div className="col-md-12 greybg" style={{paddingRight:'0px'}}>
    <h5 style={{marginBottom:'3%'}}>Aluminium Tubes</h5>
 <ul className="nav nav-pills nav-justified">
        <li className="nav-item producttab" style={{
          backgroundColor: type=='alutube' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link " style={{
          color: type=='alutube' ? '#fff' : '#000'
        }} aria-current="page" onClick={()=>settabtype('alutube')} >COLLAPSIBLE ALUMINIUM TUBES</a>
        </li>
       
        <li className="nav-item producttab" style={{
          backgroundColor: type=='lamtube' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link" style={{
          color: type=='lamtube' ? '#fff' : '#000'
        }} onClick={()=>settabtype('lamtube')}>LAMINATE TUBES</a>
        </li>
        <li className="nav-item producttab" style={{
          backgroundColor: type=='spec' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link" style={{
          color: type=='spec' ? '#fff' : '#000'
        }} onClick={()=>settabtype('spec')}>SPECIFICATIONS</a>
        </li>
   
      </ul>
      <div className="product_desc">
      {type=='alutube'?(
 <div className="row">
<p>MENA-PAC are agents and stockists of aluminium collapsible tubes produced by one of India & China premier tube manufacturers who are have all the necessary quality control systems in place to meet the highest standards.</p>
<p>We offer following products & services:</p>
<div className="col-md-12">
<ul>
<li>All fill sizes ranging from 3.5gm to 100gm available.</li>
<li>Cylindrical and Conical tubes.</li>
<li>Up to 4-colour print.</li>
<li>Standard and specialised internal lacquers for different products.</li>
<li>Flexible production runs.</li>
<li>Short lead times.</li>
<li>Warehouse facility in UAE DUBAI to hold local stocks.</li>

</ul>
</div>

<p>Quality is a critical element of every tube we sell. ISO 9002 accreditation in the manufacturing plant ensures both on-line and finished product quality checks. Full traceability and certificates of analysis (FIR) are available with every consignment. So whether it be for the Pharmaceutical, Adhesive, Veterinary, Art or Cosmetics industry, MENA PAC has a quality aluminium tube to enhance your packaging and sell your product.</p>
<p>Aluminium Collapsible Tubes are suitable for various applications with compatible internal protective coatings or internally plain include:</p>
 <div className="col-md-12">
 <ul>
 <li>Pharmaceutical Creams and Ointments.</li>
<li>Ophthalmic tubes for eye care.</li>
<li>Cosmetic products –Skin Care and Hair Care applications.</li>
<li>Food products-Condensed milk, Jams, Sausages etc.</li>
<li>Adhesive Products-Cyanoacrylate adhesives, silicon-based adhesives,
    2 Pack adhesives for various applications.</li>

 </ul>
 </div>

 </div>
      ):null}
      
      {type=='lamtube'?(
        <div className="row">
       <p>Laminated tubes offer products a competitive edge in the market and are used across the globe for packaging in personal care, food, pharma and industrial applications. The oral care industry by itself contributes to almost 70% of the total production of laminated tubes, while an increasing number of them are being used in the cosmetics sector.</p>
<p>With excellent barrier properties, laminated tubes are a cost-effective solution to help increase a product’s shelf life. Their smooth, flexible and soft exteriors deliver excellent sealability and enable high filling line speed during the packaging process. Their features include shoulder barriers for superior flavor and moisture retention, pin-hole orifices for low viscosity products and nozzle seals with an option of hot foil stamping. MENAPAC also produces tamper evident closures for protection of products. Tube dispensing nozzles or applicators can be further customized on request.</p>
       <p>Laminated tubes allow for consistent quality and performance reliability. They are adaptable to a wide range of caps, i.e. Fez / Flowerpot, Stand-up, Flip-top and custom-designed. Laminated Tubes can be customized into different varieties of coloured tubes and transparent tubes with Polyester barrier for products with contact clarity.</p>
       <p>At MENAPAC, we ensure to utilise cutting-edge technologies to ensure stronger fusion between the shoulder and body. Our manufacturers world class LTP printing technology ensures superior resistance to print bleed, allowing up to 6 colours plus a varnish overcoat with a choice of full or spot varnish. Our ultra-modern R&D cell is equipped with the most sophisticated equipment for testing and new product development. The state-of-the-art, in-house facilities for R&D and graphic design are made available for development & testing requirements.</p>
       <h5>LAMINATES TUBES FOR BEAUTY CARE</h5>
       <p>We will make your products shine! Eye-catching and stylish packaging is a must -above all in the cosmetics sector which is so dependent on looks. Of course it has to be practical, but it must turn heads at the same time. Using tube laminate from MENAPAC makes this possible – we have an enormous range of styles ready to use and give every customer the freedom to decide which version is right for them.</p>
       <p>MENAPAC offers high differentiation possibilities through various laminate effects. Depending on your requirements, white, transparent or silver base laminate is available in different thicknesses. Our luxurious Silver laminate in particular helps your products to stand out at the point of sale; a high gloss metallised film makes the laminate shimmer spectacularly. We are also able to design extravagant special effects to give your products that certain something.</p>
       <h5>LAMINATE TUBES FOR ORAL CARE</h5>
       <p>We hold it in our hands every day – open, squeeze, close, done – and put it back on the shelf without a second thought and without ever asking where it actually comes from, that toothpaste tube. Our manufacturing facility is among the world’s leading manufacturers, a position which we owe to our high standards when it comes to optimisation and improvement and our years of development and production process experience.</p>
      <p>MENAPAC is now established as the tube laminate partner for oral hygiene and our customers are able to select from our comprehensive portfolio in this area. They know that we are committed to the success of their products. We have all the necessary expertise to drive development forward and constantly expand our range. To achieve this, we are deeply committed to R&D and technical support. Customers can count on the guaranteed availability of raw materials and our outstanding customer service. We provide comprehensive support and advice to our customers, from the idea through implementation to delivery, and can be contacted about any concerns at any time. Our sales team has in-depth knowledge of the market and cooperates closely with the leading manufacturers of tube machines.</p>
      <h5>OTHER LAMINATE TUBE APPLICATIONS</h5>
       <p>In addition to our large portfolio in the field of oral hygiene and cosmetics, MENAPAC tube laminates are also successfully used for certain types of packaging in the food, non-food and pharmaceutical sectors. Here too, our laminates add value to the products in a very special way and fulfil important marketing criteria – without skimping on functionality. Our tube laminates come into play wherever design and purpose come together in packaging: they can be printed in a multitude of ways, offer numerous options for finishing and are extremely well suited for machine processing. We pursue innovative ideas and suggestions in this field with great passion in order to be able to make statements with your products and to establish standards for you.</p>
       <p>Our product offering:</p>
       <div className="col-md-12">
        <ul>
        <li>EVOH based barrier laminates</li>
<li>Polyster based barrier laminates</li>
<li>Metalized Polyster based laminates</li>
<li>Aluminium barrier laminates of all thickness</li>
<li>Double barrier laminates (Aluminium & EVOH)</li>

        </ul>
       </div>
       <p></p>
       <p></p>
       
       </div>
      ):null}
       {type=='spec'?(
        <div className="row">
      <table className='table table-bordered'>
         <thead>
            <tr>
                <th> Tube Diameter</th>
                <th> Total Thickness</th>
            </tr>
         </thead>
         <tbody>
            <tr>
                <td>13.5 mm</td>
                <td>0.07 - 0.10 mm</td>
            </tr>
            <tr>
                <td>16 mm</td>
                <td>0.13 - 0.17 mm</td>
            </tr>
            <tr>
                <td>22 mm</td>
                <td>0.13 - 0.17 mm</td>
            </tr>
            <tr>
                <td>25 mm</td>
                <td>0.13 - 0.17 mm</td>
            </tr>
            <tr>
                <td>30 mm</td>
                <td>0.15 - 0.22 mm</td>
            </tr>
            <tr>
                <td>35 mm</td>
                <td>0.15 - 0.22 mm</td>
            </tr>
	
         </tbody>

        </table>
        <p> Size  5g, 10g, 15g, 20g, 25g, 30g, 50g, 100g<br/>
        Technics<br/>
        Offset Printing<br/>
        Specification<br/>
        Diameter from 13.5 to 40 mm, Volume 5g to 100g</p>

       </div>
      ):null}
        </div>
                </div>

            </div>

            
        
            <div style={{display:"inline-block"}}></div>
        </div>

        <div className="container help mt-5 maincontent">
 <h5 style={{marginBottom:'3%'}}>Aluminium Foil</h5>
            <div className='row'>
                <div className="col-md-5" style={{paddingLeft:'0px'}}>
                <Carousel>
   
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={alu6}
        alt="Second slide"
        />
        <Carousel.Caption>
        
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={alu7}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    
   
</Carousel>
              
                </div>
              
 <div className="col-md-7 greybg tabsec" style={{paddingRight:'0px'}}>
 <ul className="nav nav-pills nav-justified">
        <li className="nav-item producttab" style={{
          backgroundColor: type2=='product' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link " style={{
          color: type2=='product' ? '#fff' : '#000'
        }} aria-current="page" onClick={()=>settabtype2('product')} >Product Features</a>
        </li>
        <li className="nav-item producttab" style={{
          backgroundColor: type2=='spec' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link" style={{
          color: type2=='spec' ? '#fff' : '#000'
        }} onClick={()=>settabtype2('spec')}>Specification</a>
        </li>
   
      </ul>
      <div className="product_desc">
      {type2=='product'?(
 <div className="row">
    <p>Product Name:  Pharmaceutical alu alu foil cold forming alu blister foil with good quality </p>
    <p>Structure:	OPA/AL/PVC	</p>
    <p>Thickness:	0,14mm 0.16mm 0.18mm or as your requirement</p>
    <p>Width:	50-810mm	</p>
    <p>Inner Core Diameter:	76mm	</p>	
			
    <p>Certificates	ISO 9001,ISO 14001</p>
        <h6>Advantages</h6>
        <div className="col-md-12">
        <ul  style={{listStyle:'circle'}}>
            <li>100% barrier to moisture, air, gases, light</li>
            <li> Maximum fracture-free forming capabilities ensures "zero defect"</li>
            <li> Superior process results in long term de-lamination resistance</li>
            <li> Wide range of color and print options</li>
            <li> Superior forming characteristics</li>
            <li> Excellent thermal stability</li>

        </ul>
        </div>
       

</div>
      ):null}
      
      {type2=='spec'?(
        <div className="row" style={{display:'contents'}}>
        <table className='table table-bordered'>
         <thead>
            <tr>
                <th>No</th>
                <th> Type / Structure</th>
                <th> Thickness</th>
                <th> Gram weight</th>
            </tr>
         </thead>
         <tbody>
            <tr>
                <td>1</td>
                <td>Aluminum Foil / PE</td>
                <td>6μ-10 mm</td>
                <td rowspan="6" style={{verticalAlign:'middle'}}>Customized for different applications</td>
            </tr>
            <tr>
                <td>2</td>
                <td>VMPET/PE</td>
                <td rowspan="5"></td>

            </tr>
            <tr>
                <td>3</td>
                <td>Aluminum Foil / PET/ PE</td>
            </tr>
            <tr>
                <td>4</td>
                <td>PET / Aluminum Foil / PE</td>
            </tr>
            <tr>
                <td>5</td>
                <td>VMPET / Aluminum Foil</td>
            </tr>
            <tr>
                <td>6</td>
                <td>can be can be custom-made &
produced, Fireproof, Printed or Colored
</td>
            </tr>
        
	
         </tbody>

        </table>
        


       </div>
      ):null}
        </div>
                </div>

            </div>

            
        
            <div style={{display:"inline-block"}}></div>
        </div>
      
        </section>
  )
}
