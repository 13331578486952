//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../../css/Sonoco.css';
import banner4 from '../../img/banner4.jfif'
import banner from '../../img/banners/14.jfif'
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-bootstrap';
import slider1 from '../../img/slider/1.jfif'
import cap1 from '../../img/products/cap1.PNG'
import cap4 from '../../img/products/cap3.PNG'
import cap3 from '../../img/products/cap4.jpg'
import cap5 from '../../img/products/cap5.jpg'
import cap2 from '../../img/products/cap6.jpg'

export default function Caps() {
  
    const [type, settype] = useState('product')
function settabtype(val){
    settype(val)
}
  return (

    <section id="cd-intro">
<Helmet>
        <title>MenaPac | Caps</title>
      </Helmet>
        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="bannertitle" >CAP'S</h1>
                            {/* <p>Search for answers or explore regular help topics below</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="container help mt-5 maincontent">
            <h6>WE CAN MAKE CUSTOM-MADE PRODUCTS ESPECIALLY FOR CUSTOMER NEEDS</h6>
<p>Our expertise in customer consultation is based on over 60 years of experience in plastics processing. Whether it’s in relation to design, the choice of materials or the appropriate production technology, we work with our customers to develop competitive, high-quality, attractive packaging solutions.</p>
            <div className='row'>
                <div className="col-md-5 n-slider" style={{paddingLeft:'0px'}}>
                <Carousel>
   
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={cap1}
        alt="Second slide"
        />
        <Carousel.Caption>
        
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={cap2}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>

    <Carousel.Item>
        <img
        className="d-block w-100"
        src={cap3}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={cap4}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={cap5}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    
   
</Carousel>
              
                </div>
              
 <div className="col-md-7 greybg tabsec" style={{paddingRight:'0px'}}>
 <ul className="nav nav-pills nav-justified">
        <li className="nav-item producttab" style={{
          backgroundColor: type=='product' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link " style={{
          color: type=='product' ? '#fff' : '#000'
        }} aria-current="page" onClick={()=>settabtype('product')} >CAP'S</a>
        </li>
       
        <li className="nav-item producttab" style={{
          backgroundColor: type=='crc' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link" style={{
          color: type=='crc' ? '#fff' : '#000'
        }} onClick={()=>settabtype('crc')}>CRC</a>
        </li>
        <li className="nav-item producttab" style={{
          backgroundColor: type=='measurement' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link" style={{
          color: type=='measurement' ? '#fff' : '#000'
        }} onClick={()=>settabtype('measurement')}>Measurement Cup </a>
        </li>
   
      </ul>
      <div className="product_desc">
      {type=='product'?(
 <div className="row">
 <p>DIAMETER: 28,00</p>
 <p>HEIGHT: 20,15</p>
 <p>NECK TYPE: 28 MM PCO 1881</p>
 <p>MATERIAL: CAP: PP; RING: PE</p>


 </div>
      ):null}
      
      {type=='crc'?(
        <div className="row">
       <p>DIAMETER: 28,00</p>
 <p>HEIGHT: 22,75</p>
 <p>NECK TYPE: 28 MM PCO 1881</p>
 <p>MATERIAL: PP</p>
       </div>
      ):null}
       {type=='measurement'?(
        <div className="row">
      <table className="table table-bordered">
       <tbody>
        <tr>
            <td>Application</td>
            <td>Medicine cup is widely in hospital, clinic, household, etc.</td>
            </tr>
            <tr>
            <td>Material</td>
            <td>High quality PP</td>
           </tr>
           <tr>
            <td>Features</td>
            <td>1.With clear sacle of  CC, ML & OZ
	2.Easy to use and carry
	3.without lid
	4.The edge is smooth
	5.Sterile and hygeian</td>
           </tr>
<tr>
    <td>Size</td>
    <td>30 ml or 60ml</td>
   
</tr>
<tr>
    <td>Color</td>
    <td>Transparent</td>
</tr>
<tr>
    <td>Service</td>
    <td>OEM is available for medicine cup</td>
</tr>
<tr>
    <td>Packing</td>
    <td>1000pcs/bag</td>
</tr>
<tr>
    <td>Sterilization</td>
    <td>By EO gas </td>
</tr>
	
   
       </tbody>
      </table>

       </div>
      ):null}
        </div>
                </div>

            </div>

            
        
            <div style={{display:"inline-block"}}></div>
        </div>
      
        </section>
  )
}
