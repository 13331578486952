//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../../css/Sonoco.css';
import banner from '../../img/banners/22.jfif'
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-bootstrap';

import excipient from '../../img/products/excipient.jfif'
import excipient2 from '../../img/products/excipient2.jfif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons'

const circlecheck = <FontAwesomeIcon icon={faCircleCheck} />
export default function Excipients() {
  
    const [type, settype] = useState('product')
function settabtype(val){
    settype(val)
}
  return (

    <section id="cd-intro">
<Helmet>
        <title>MenaPac | Excipients</title>
      </Helmet>
        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="bannertitle" >Excipients</h1>
                            {/* <p>Search for answers or explore regular help topics below</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="container help mt-5 maincontent">

            <div className='row'>
                <div className="col-md-5 n-slider" style={{paddingLeft:'0px'}}>
                <Carousel>
   
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={excipient}
        alt="Second slide"
        />
        <Carousel.Caption>
        
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={excipient2}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    
   
</Carousel>
              
                </div>
              
 <div className="col-md-7 greybg tabsec" style={{paddingRight:'0px'}}>
 <ul className="nav nav-pills nav-justified">
        <li className="nav-item producttab" style={{
          backgroundColor: type=='product' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link " style={{
          color: type=='product' ? '#fff' : '#000'
        }} aria-current="page" onClick={()=>settabtype('product')} >Product Description</a>
        </li>
        <li className="nav-item producttab" style={{
          backgroundColor: type=='spec' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link" style={{
          color: type=='spec' ? '#fff' : '#000'
        }} onClick={()=>settabtype('spec')}>Specification</a>
        </li>
   
      </ul>
      <div className="product_desc">
      {type=='product'?(
 <div className="row">
<p>The word excipient is derived from the Latin excipere, meaning 'to except', which is simply explained as 'other than'. Pharmaceutical excipients are basically everything other than the active pharmaceutical ingredient. Ideally, excipients should be inert, not only possessing the ability to react with other ingredients in the formulation, but also to cause adverse and hypersensitivity reactions in patients.</p>
 <div>
    <ul>
        <li>Palonosetron HCl</li>
        <li>Cefuroxime Sodium</li>
        <li>Cefradine with Arginine</li>
        <li>Flucloxacillin Sodium</li>
        <li>Levalbuterol Tartrate</li>
        <li>Sitagliptin Phosphate</li>
        <li>Metaxalone</li>
        <li>Quetiapine Fumarate</li>
        <li>Pinaverium Bromide</li>
        <li>Palonosetron HCl</li>
        <li>Memantine HCl</li>
        <li>Drotaverine HCl</li>
    </ul>
    </div>

 
 </div>

      ):null}
      
      {type=='spec'?(
        <div className="row">
        
       </div>
      ):null}
        </div>
                </div>

            </div>

            
        
            <div style={{display:"inline-block"}}></div>
        </div>
      
        </section>
  )
}
