import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import banner1 from '../../img/slider/1.jfif'
import banner2 from '../../img/slider/2.jfif'
import banner3 from '../../img/1.jfif'
import banner4 from '../../img/banner4.jfif'
import slider1 from '../../img/slider/1.jfif'
import slider2 from '../../img/slider/2.jfif'
import slider3 from '../../img/slider/3.jfif'
import slider4 from '../../img/slider/4.jfif'
import slider5 from '../../img/slider/6.jfif'
import about from '../../img/banners/5.jfif'

import { Carousel } from 'react-bootstrap'
import '../../css/Custom.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlay } from '@fortawesome/free-solid-svg-icons'
const more = <FontAwesomeIcon icon={faPlay} style={{fontSize:'10px'}} />
export default function Home1() {

  return (
    <>
    <Carousel>
    <Carousel.Item>
        <img
        className="d-block w-100"   
        src={slider1}
        alt="First slide"
        style={{marginTop:'-150px'}}
        />
        <Carousel.Caption className={"cap"}>
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={slider2}
        alt="Second slide"
        />
        <Carousel.Caption>
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={slider3}
        alt="Second slide"
        
        />
        <Carousel.Caption>
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={slider4}
        alt="Second slide"
        style={{marginTop:'-150px'}}
        />
        <Carousel.Caption>
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
    </Carousel.Item>
   
</Carousel>
<div className="container help mt-5 maincontent">

<div className='row'>
    <div className="col-md-6" style={{paddingLeft:'0px'}}>
    <img src={about}  style={{width:'100%'}}/>
    </div>
  
<div className="col-md-6 greybg" style={{paddingRight:'0px'}}>
      <h2>WHO WE ARE ?</h2>
      <p style={{marginTop:'6%'}}>We have tried to be a step ahead of time pioneering the way to bring cost effective solutions for the Food, Beverage, Pharma & Cosmetic industry yet maintaining high standards of quality through continuous dedication & innovative ideas. EMAD TRADE HOUSE in the Middle East has overcome a decade of challenges & grown to become one of the leading trading house with warehousing facility in Jebel Ali, Dubai & fully geared to serve the industry JIT basis…</p>
    </div>

</div>

<div id="thegem-divider-635131ae764a9" className="gem-divider  fullw gem-divider-style-1" style={{borderColor: '#0a0a0a'}}><svg width="100%" height="1px"><line x1={0} x2="100%" y1={0} y2={0} stroke="#0a0a0a" strokeWidth={2} strokeLinecap="black" strokeDasharray="4, 4" /></svg></div>

<div style={{display:"inline-block"}}></div>
<div className="row">

    <div className="col-md-4 ">
        <div className="homebox">
       <img src={banner1} style={{width:'100%'}}/>
       <div className="centered">Stock Sales</div>
       </div>
       <div className="hometext">
       <p className="homep">1,500/ Euro Pallet Places holding backup inventory in our certified Warehousing facility</p>
       </div>
        <p style={{color:'red'}}>EXPLORE NOW &nbsp; {more}</p>
       
      
    </div>
    <div className="col-md-4 ">
    <div className="homebox">
    <img src={banner1} style={{width:'100%'}}/>
    <div className="centered">Stock Sales</div>
    </div>
    <div className="hometext">
    <p className="homep">50+ diverse team members customer service team in UAE catering to MENA & South East Asia Market</p>
    </div>
    <p style={{color:'red'}}>EXPLORE NOW &nbsp; {more}</p>
   
    </div>
    <div className="col-md-4 ">
    <div className="homebox">
    <img src={banner1} style={{width:'100%'}}/>
    <div className="centered">Stock Sales</div>
    </div>
    <div className="hometext">
    <p className="homep">In house Logistics team serving MENA + South Asia effectively</p>
    </div>
    <p style={{color:'red'}}>EXPLORE NOW &nbsp; {more}</p>
    
    </div>
</div>

<div className="row">

    <div className="col-md-4 ">
        <div className="homebox">
       <img src={banner1} style={{width:'100%'}}/>
       <div className="centered">Stock Sales</div>
       </div>
       <div className="hometext">
       <p className="homep">1,500/ Euro Pallet Places holding backup inventory in our certified Warehousing facility</p>
       </div>
        <p style={{color:'red'}}>EXPLORE NOW &nbsp; {more}</p>
       
      
    </div>
    <div className="col-md-4 ">
    <div className="homebox">
    <img src={banner1} style={{width:'100%'}}/>
    <div className="centered">Stock Sales</div>
    </div>
    <div className="hometext">
    <p className="homep">50+ diverse team members customer service team in UAE catering to MENA & South East Asia Market</p>
    </div>
    <p style={{color:'red'}}>EXPLORE NOW &nbsp; {more}</p>
   
    </div>
    <div className="col-md-4 ">
    <div className="homebox">
    <img src={banner1} style={{width:'100%'}}/>
    <div className="centered">Stock Sales</div>
    </div>
    <div className="hometext">
    <p className="homep">In house Logistics team serving MENA + South Asia effectively</p>
    </div>
    <p style={{color:'red'}}>EXPLORE NOW &nbsp; {more}</p>
    
    </div>
</div>

</div>

<div className="container-fluid homebg" style={{background:`url(${banner3})`}}>
      <div className="homebgtext" style={{textAlign:'center'}}>
    <h2 >INTERESTED IN OUR WORK?</h2>
    <button className='homebgbtn'>CONTACT NOW</button>
    </div>
</div>

<div className="container-fluid homebg2" style={{background:`url(${banner4})`}}>
<h2 style={{textAlign:'center',paddingBottom:'1%'}}>KEY PARTNERS</h2>
   
   <Carousel>
    <Carousel.Item>
    
    <div className="testi">
        <p style={{textAlign:'left',color:'red',marginLeft:'13%',lineHeight:'20px'}}>Name <br/> Designation</p>
       
    
    <p style={{textAlign:'left',marginLeft:'13%'}}>Emad Trade House is a successful, loyal, and trustworthy  business partner of Constantia Flexibles Pharma <br/>
    in the GCC region for more than 10 years. Mr. Muhammad Shabbir and his son Emad have an outstanding network <br/>
   in the region and an excellent understanding of the market and it needs. Together with the well organized and <br/>
   trained customer service team in Dubai and Karachi, Emad Trade House is THE ideal partner for Constantia Flexibles Pharma in this region</p>
    </div>
    </Carousel.Item>
    <Carousel.Item>
    
    <div className="testi">
        <p style={{textAlign:'left',color:'red',marginLeft:'13%',lineHeight:'20px'}}>Name <br/> Designation</p>
       
    
    <p style={{textAlign:'left',marginLeft:'13%'}}>Emad Trade House is a successful, loyal, and trustworthy  business partner of Constantia Flexibles Pharma <br/>
    in the GCC region for more than 10 years. Mr. Muhammad Shabbir and his son Emad have an outstanding network <br/>
   in the region and an excellent understanding of the market and it needs. Together with the well organized and <br/>
   trained customer service team in Dubai and Karachi, Emad Trade House is THE ideal partner for Constantia Flexibles Pharma in this region</p>
    </div>
    </Carousel.Item>
    <Carousel.Item>
    
    <div className="testi">
        <p style={{textAlign:'left',color:'red',marginLeft:'13%',lineHeight:'20px'}}>Name <br/> Designation</p>
       
    
    <p style={{textAlign:'left',marginLeft:'13%'}}>Emad Trade House is a successful, loyal, and trustworthy  business partner of Constantia Flexibles Pharma <br/>
    in the GCC region for more than 10 years. Mr. Muhammad Shabbir and his son Emad have an outstanding network <br/>
   in the region and an excellent understanding of the market and it needs. Together with the well organized and <br/>
   trained customer service team in Dubai and Karachi, Emad Trade House is THE ideal partner for Constantia Flexibles Pharma in this region</p>
    </div>
    </Carousel.Item>
    
    </Carousel>
</div>

</>

  )
}
