//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../../css/Sonoco.css';
import banner from '../../img/banners/21.jfif'
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-bootstrap';

import glass from '../../img/products/glass.jpg'
import glass2 from '../../img/products/glass2.jfif'
import glass3 from '../../img/products/glass3.jfif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons'

const circlecheck = <FontAwesomeIcon icon={faCircleCheck} />
export default function Glass() {
  
    const [type, settype] = useState('product')
function settabtype(val){
    settype(val)
}
  return (

    <section id="cd-intro">
<Helmet>
        <title>MenaPac | Glass</title>
      </Helmet>
        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="bannertitle" >Glass</h1>
                            {/* <p>Search for answers or explore regular help topics below</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="container help mt-5 maincontent">

            <div className='row'>
                <div className="col-md-5" style={{paddingLeft:'0px'}}>
                <Carousel>
   
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={glass}
        alt="Second slide"
        />
        <Carousel.Caption>
        
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={glass2}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    
    {/* <Carousel.Item>
        <img
        className="d-block w-100"
        src={glass3}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item> */}
</Carousel>
              
                </div>
              
 <div className="col-md-7 greybg tabsec" style={{paddingRight:'0px'}}>
 <ul className="nav nav-pills nav-justified">
        <li className="nav-item producttab" style={{
          backgroundColor: type=='product' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link " style={{
          color: type=='product' ? '#fff' : '#000'
        }} aria-current="page" onClick={()=>settabtype('product')} >Product Description</a>
        </li>
        <li className="nav-item producttab" style={{
          backgroundColor: type=='spec' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link" style={{
          color: type=='spec' ? '#fff' : '#000'
        }} onClick={()=>settabtype('spec')}>Specification</a>
        </li>
   
      </ul>
      <div className="product_desc">
      {type=='product'?(
 <div className="row">
<p>Menapac offers a wide range of amber glass bottles, we are one of the main suppliers with more than 200 million bottles.  We use amber glass bottles to ensure every aspect of pharmaceutical manufacturing of the products are safe, effective, and of high quality.</p>

<p>Amber glass bottles are commonly used in the pharmaceutical industry for the storage and packaging of various medications, dietary supplements, and other pharmaceutical products. The primary purpose of using amber glass bottles is to provide protection against the harmful effects of light.</p>

<p>Pharmaceutical products can be sensitive to light and other environmental factors, which can cause degradation or oxidation of the product over time. Amber glass is a specialized glass that has been colored with iron, sulfur, or other additives to provide a dark, opaque color that blocks most of the visible light spectrum.</p>

<p>By using our  Menapac amber glass bottles, pharmaceutical products can be protected from UV and visible light, which can cause photochemical reactions and degradation. This can help to maintain the quality, potency, and stability of the product and can help to extend its shelf life.</p>

<p>In addition to light protection, our Menapac amber glass bottle is also relatively inert, which means that it is less likely to react with the product inside the bottle, compared to other types of glass. This can help to reduce the risk of contamination or other chemical reactions that can affect the quality of the product.</p>

 

 
 </div>

      ):null}
      
      {type=='spec'?(
        <div className="row">
        <p><strong>Our product range varies from the following sizes:</strong></p>
        <ul style={{listStyle: "none"}}>
          <li>-	20ml</li>
          <li>-	30ml</li>
          <li>-	60ml</li>
          <li>-	100ml</li>
          <li>-	125ml</li>
          <li>-	150ml</li>
          <li>-	200ml</li>
        </ul>
       </div>
      ):null}
        </div>
                </div>

            </div>

            
        
            <div style={{display:"inline-block"}}></div>
        </div>
      
        </section>
  )
}
