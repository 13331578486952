//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../../css/Sonoco.css';
import sonocoimg1 from '../../img/sonoco/row1-1.jpg'
import sonocoimg2 from '../../img/sonoco/row1-2.jpg'
import sonocoimg3 from '../../img/sonoco/row1-3.jpg'
import sonocoimg4 from '../../img/sonoco/row2-1.jpg'
import sonocoimg5 from '../../img/sonoco/row2-2.jpg'
import sonocoimg6 from '../../img/sonoco/row2-3.jpg'
import sonocoimg7 from '../../img/sonoco/row3-1.jpg'
import sonocoimg8 from '../../img/sonoco/row3-2.jpg'
import sonocoimg9 from '../../img/sonoco/row3-3.jpg'
import banner from '../../img/banners/4.jfif'
import load from '../../img/load.gif'
import axios from 'axios';
import { Helmet } from 'react-helmet';


export default function Sonoco() {
    // const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [formdata, setformdata] = useState({
       name:'',
       email:'',
       phone:'',
       message:''
    })
   
    const [resultmsg, setresultmsg] = useState('')
    const [errorsts, seterrorsts] = useState(0)
    const [loadingshow, setloadingshow] = useState(false)
    //const API_PATH=`${SERVER_URL}customs_enquiry.php`;
    const API_PATH='https://menapac.com/modal/sonoco_enquiry.php';
   // const API_PATH=`${SERVER_URL}sonoco_enquiry.php`;
    function handleFormSubmit(event){
        event.preventDefault();
        console.log(formdata);
        if(formdata.name!="" && formdata.email!="" ){
      
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formdata.email))
        {
            
          setloadingshow(true)
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: formdata
          })
          .then((res)=>{
            setloadingshow(false)
              //setresultmsg(res.data.sent)
              console.log(res.data.message)
              setresultmsg(res.data.message);
              //console.log(resultmsg)
          })
            // .then(res => {
            //   this.setState({
            //     mailSent: res.data.sent
            //   })
            // })
            .catch(error => console.log(error.message));
        }else{
            setresultmsg('Please enter a valid email address');
        }
      }else{
        setresultmsg('Please enter all required fields');
      }
    }



    const [aclass, setaclass1] = useState('')
    const [contentclass, setcontentclass1] = useState('')
    const [aclass2, setaclass2] = useState('')
    const [contentclass2, setcontentclass2] = useState('')
    const [aclass3, setaclass3] = useState('')
    const [contentclass3, setcontentclass3] = useState('')
    const [aclass4, setaclass4] = useState('')
    const [contentclass4, setcontentclass4] = useState('')
    const [aclass5, setaclass5] = useState('')
    const [contentclass5, setcontentclass5] = useState('')
    const [aclass6, setaclass6] = useState('')
    const [contentclass6, setcontentclass6] = useState('')
    const [aclass7, setaclass7] = useState('')
    const [contentclass7, setcontentclass7] = useState('')
    const [aclass8, setaclass8] = useState('')
    const [contentclass8, setcontentclass8] = useState('')
    const [aclass9, setaclass9] = useState('')
    const [contentclass9, setcontentclass9] = useState('')
    const [current, setcurrent] = useState('')

    function showservice(val) {        
        if(current == val){
            setaclass1('')            
            setcontentclass1('')
            setaclass2('')            
            setcontentclass2('')
            setaclass3('')            
            setcontentclass3('')
            setaclass4('')            
            setcontentclass4('')
            setaclass5('')            
            setcontentclass5('')
            setaclass6('')            
            setcontentclass6('')
            setaclass7('')            
            setcontentclass7('')
            setaclass8('')            
            setcontentclass8('')
            setaclass9('')            
            setcontentclass9('')
            setcurrent('')
        }else{
            setaclass1('')            
            setcontentclass1('')
            setaclass2('')            
            setcontentclass2('')
            setaclass3('')            
            setcontentclass3('')
            setaclass4('')            
            setcontentclass4('')
            setaclass5('')            
            setcontentclass5('')
            setaclass6('')            
            setcontentclass6('')
            setaclass7('')            
            setcontentclass7('')
            setaclass8('')            
            setcontentclass8('')
            setaclass9('')            
            setcontentclass9('')
            setcurrent('')

            if(val == 'service-1'){            
                setaclass1('active')            
                setcontentclass1(val)
                setcurrent(val)           
            }else if(val == 'service-2'){
                setaclass2('active')            
                setcontentclass2(val)
                setcurrent(val)
            }else if(val == 'service-3'){
                setaclass3('active')            
                setcontentclass3(val)
                setcurrent(val)
            }else if(val == 'service-4'){
                setaclass4('active')            
                setcontentclass4(val)
                setcurrent(val)
            }else if(val == 'service-5'){
                setaclass5('active')            
                setcontentclass5(val)
                setcurrent(val)
            }else if(val == 'service-6'){
                setaclass6('active')            
                setcontentclass6(val)
                setcurrent(val)
            }else if(val == 'service-7'){
                setaclass7('active')            
                setcontentclass7(val)
                setcurrent(val)
            }else if(val == 'service-8'){
                setaclass8('active')            
                setcontentclass8(val)
                setcurrent(val)
            }else if(val == 'service-9'){
                setaclass9('active')            
                setcontentclass9(val)
                setcurrent(val)
            }
        }
        

        
        //console.log(val)
    }

  return (

    <section id="cd-intro">
<Helmet>
        <title>MenaPac | Sonoco</title>
      </Helmet>
        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="bannertitle" >Parcels Packaging Solutions</h1>
                            {/* <p>Search for answers or explore regular help topics below</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="container help mt-5">

            <div className='sonoco_main-cont'>
                <div className={`sonoco_name ${aclass}`} id="ser-1">
                    <a onClick={() => showservice('service-1')} className="services" flag="ser-1">
                        <img src={sonocoimg1} width={120} height={100} />
                        <h2>Cost-effective seasonal solutions for 2 to 8&ordm;C shipments</h2>
                    </a>
                </div>
                <div className={`sonoco-content ${contentclass}`} id="service-1" style={{display: 'none'}}>					
                    <div className="distributor_title ">			
                        <div className="distributor_content" style={{display: 'block'}}>
                            <p>ChillTherm® pre-qualified systems offer 2 to 8ºC temperature control for uop to 120 hours. Tested to a wide range of ambient temperatures, these systems give you a single cost-effective solutions for shipping various volumes in all seasons.</p>
                            <ul className="ul-holder">
                                <li>
                                    <h3>Temperature range</h3>
                                    <p>2 to 8ºC</p>
                                </li>
                                <li>
                                    <h3>Duration</h3>
                                    <p>Up to 120 hours</p>
                                </li>
                                <li>
                                    <h3>Payload size</h3>
                                    <p>2L up to 266L</p>
                                </li>
                                <li>
                                    <h3>Platform</h3>
                                    <p>Seasonal configuration</p>
                                </li>
                                <li>
                                    <h3>Insulation</h3>
                                    <p>EPS (Expanded Polystyrene)</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                
                <div className={`sonoco_name ${aclass2}`} id="ser-2" style={{display: 'none'}}>
                    <a onClick={() => showservice('service-2')} className="services" flag="ser-2">
                        <img src={sonocoimg2} width={120} height={100} />
                        <h2>Cost effective solutions for refrigerated, CRT & frozen shipments</h2>
                    </a>
                </div>            
                <div className={`sonoco-content ${contentclass2}`} id="service-2" style={{display: 'none'}}>					
                    <div className="distributor_title ">
                        <h3>Apa itu biaya tambahan bahan bakar?</h3>			
                        <div className="distributor_content" style={{display: 'block'}}>
                            <p>Biaya tambahan bahan bakar adalah cara untuk menyesuaikan jumlah yang dibayarkan untuk memindahkan barang (di kami disebut paket) dengan mempertimbangkan variasi harga bahan bakar yang signifikan, dibandingkan dengan tingkat historisnya. Jangan khawatir, ini bukan hal yang luar biasa dan sebagian besar operator dan pengirim berpartisipasi dalam semacam program biaya tambahan bahan bakar.</p>
                        </div>
                    </div>
                </div>

                
                <div className={`sonoco_name ${aclass3}`} id="ser-3">
                    <a onClick={() => showservice('service-3')}  className="services" flag="ser-3">
                        <img src={sonocoimg3} width={120} height={100} />
                        <h2>Cost effective solutions for refrigerated, CRT & frozen shipments</h2>
                    </a>
                </div>
                <div className={`sonoco-content ${contentclass3}`} id="service-3" style={{display: 'none'}}>					
                    <div className="distributor_title ">		
                        <div className="distributor_content" style={{display: 'block'}}>
                            <p>Certis premium shippers are ideal for one-way global shipments of pharmaceutical products. Use the same packout for refrigerated, CRT and frozen solutions for lowest cost of ownership.</p>
                            <ul className="ul-holder">
                                <li>
                                    <h3>Temperature range</h3>
                                    <p>2-8ºC | 15-25ºC | &lt;-20ºC</p>
                                </li>
                                <li>
                                    <h3>Duration</h3>
                                    <p>Up to 120 hours</p>
                                </li>
                                <li>
                                    <h3>Payload size</h3>
                                    <p>3L up to 56L</p>
                                </li>
                                <li>
                                    <h3>Platform</h3>
                                    <p>Same packout configurations for durations 48, 72 and 120 hours</p>
                                </li>
                                <li>
                                    <h3>Ease</h3>
                                    <p>Simply change the outer shipper to switch durations, and change from amber to yellow PCM bricks to move from refrigerated to CRT.</p>
                                </li>
                                <li>
                                    <h3>Insulation</h3>
                                    <p>EPS (Expanded Polystyrene), PUR (Polyurethane) &amp; VIP (Vacuum Insulation Panels)</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className={`sonoco_name ${aclass4}`} id="ser-4" style={{display: 'none'}}>
                    <a onClick={() => showservice('service-4')} className="services" flag="ser-4">
                        <img src={sonocoimg4} width={120} height={100} />
                        <h2>Cost effective solutions for refrigerated, CRT & frozen shipments</h2>
                    </a>
                </div>           
                <div className={`sonoco-content ${contentclass4}`} id="service-4" style={{display: 'none'}}>					
                    <div className="distributor_title ">
                        <h3>Apa itu biaya tambahan bahan bakar?</h3>			
                        <div className="distributor_content" style={{display: 'block'}}>
                            <p>Biaya tambahan bahan bakar adalah cara untuk menyesuaikan jumlah yang dibayarkan untuk memindahkan barang (di kami disebut paket) dengan mempertimbangkan variasi harga bahan bakar yang signifikan, dibandingkan dengan tingkat historisnya. Jangan khawatir, ini bukan hal yang luar biasa dan sebagian besar operator dan pengirim berpartisipasi dalam semacam program biaya tambahan bahan bakar.</p>
                        </div>
                    </div>
                </div>


                <div className={`sonoco_name ${aclass5}`} id="ser-5">
                    <a onClick={() => showservice('service-5')} className="services" flag="ser-5">
                        <img src={sonocoimg5} width={120} height={100} />
                        <h2>Cost effective solutions for refrigerated, CRT & frozen shipments</h2>
                    </a>
                </div>
                <div className={`sonoco-content ${contentclass5}`} id="service-5" style={{display: 'none'}}>					
                    <div className="distributor_title ">		
                        <div className="distributor_content" style={{display: 'block'}}>
                            <p>ChillTherm® is a pre-qualified range of shipping systems providing exceptional temperature control.</p>
                            <ul className="ul-holder">
                                <li>
                                    <h3>Temperature range</h3>
                                    <p>2 to 8ºC</p>
                                </li>
                                <li>
                                    <h3>Duration</h3>
                                    <p>Up to 144 hours</p>
                                </li>
                                <li>
                                    <h3>Payload size</h3>
                                    <p>5L, 10L, 20L, 40L</p>
                                </li>
                                <li>
                                    <h3>Platform</h3>
                                    <p>Same packout configuration across seasons, durations and payloads</p>
                                </li>
                                <li>
                                    <h3>Ease</h3>
                                    <p>Simply change the outer insulated shipper to switch shipment duration from 48 hours to 72 hours to 144 hours</p>
                                </li>
                                <li>
                                    <h3>Insulation</h3>
                                    <p>EPS (Expanded Polystyrene)&amp; VIP (Vacuum Insulation Panels)</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className={`sonoco_name ${aclass6}`} id="ser-6">
                    <a onClick={() => showservice('service-6')} className="services" flag="ser-6">
                        <img src={sonocoimg6} width={120} height={100} />
                        <h2>Cost effective solutions for refrigerated, CRT & frozen shipments</h2>
                    </a>
                </div>
                <div className={`sonoco-content ${contentclass6}`} id="service-6" style={{display: 'none'}}>					
                    <div className="distributor_title ">			
                        <div className="distributor_content" style={{display: 'block'}}>
                            <p>AmbiTherm® fully qualified insulated shipping solutions use traditional water-based cool packs to control temperature uo to 48 hours, protecting your cargo from extreme ambient conditions. A multi-layer film keeps packs lightweight while providing high puncture resistance and protection.</p>
                            <ul className="ul-holder">
                                <li>
                                    <h3>Temperature range</h3>
                                    <p>15 to 25ºC</p>
                                </li>
                                <li>
                                    <h3>Duration</h3>
                                    <p>Up to 48 hours</p>
                                </li>
                                <li>
                                    <h3>Payload size</h3>
                                    <p>4L to 48L</p>
                                </li>
                                <li>
                                    <h3>Insulation</h3>
                                    <p>EPS (Expanded Polystyrene)</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className={`sonoco_name ${aclass7}`} id="ser-7">
                    <a onClick={() => showservice('service-7')} className="services" flag="ser-7">
                        <img src={sonocoimg7} width={120} height={100} />
                        <h2>Cost effective solutions for refrigerated, CRT & frozen shipments</h2>
                    </a>
                </div>
                <div className={`sonoco-content ${contentclass7}`} id="service-7" style={{display: 'none'}}>					
                    <div className="distributor_title ">			
                        <div className="distributor_content" style={{display: 'block'}}>
                            <p>AmbiTech® controlled room temperature systems use the latest phase change material technology to deliver superior temperature control for pharmaceuticals</p>
                            <p>These innovative shippers use AmbiSorb, our custom inorganic salt PCM, which is both economic and non-hazardous. The all-year configuration makes packout easy.</p>
                            <ul className="ul-holder">
                                <li>
                                    <h3>Temperature range</h3>
                                    <p>15 to 25ºC</p>
                                </li>
                                <li>
                                    <h3>Duration</h3>
                                    <p>Up to 120 hours</p>
                                </li>
                                <li>
                                    <h3>Payload size</h3>
                                    <p>4L to 71L</p>
                                </li>
                                <li>
                                    <h3>Insulation</h3>
                                    <p>EPS (Expanded Polystyrene)</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className={`sonoco_name ${aclass8}`} id="ser-8">
                    <a onClick={() => showservice('service-8')} className="services" flag="ser-8">
                        <img src={sonocoimg8} width={120} height={100} />
                        <h2>Cost effective solutions for refrigerated, CRT & frozen shipments</h2>
                    </a>
                </div>
                <div className={`sonoco-content ${contentclass8}`} id="service-8" style={{display: 'none'}}>					
                    <div className="distributor_title ">			
                        <div className="distributor_content" style={{display: 'block'}}>
                            <p>FreezeTherm® dry ice shipping systems give you ready access to convenient, affordable protection for frozen products in transit. The wide range of off-the-shelf sizes means there's a FreezeTherm® solution for your application-talk to us to learn more.</p>
                            <ul className="ul-holder">
                                <li>
                                    <h3>Temperature range</h3>
                                    <p>Frozen</p>
                                </li>
                                <li>
                                    <h3>Duration</h3>
                                    <p>54 - 120+ hours</p>
                                </li>
                                <li>
                                    <h3>Payload size</h3>
                                    <p>6L up to 396L</p>
                                </li>
                                <li>
                                    <h3>Insulation</h3>
                                    <p>EPS (Expanded Polystyrene)</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className={`sonoco_name ${aclass9}`} id="ser-9">
                    <a onClick={() => showservice('service-9')} className="services" flag="ser-9">
                        <img src={sonocoimg9} width={120} height={100} />
                        <h2>Cost effective solutions for refrigerated, CRT & frozen shipments</h2>
                    </a>
                </div>
                <div className={`sonoco-content ${contentclass9}`} id="service-9" style={{display: 'none'}}>					
                    <div className="distributor_title ">		
                        <div className="distributor_content" style={{display: 'block'}}>
                            <p>FreezeTech® systems are a fully qualified, more environmentally friendly and cost-effective to dry ice for keeping products frozen during shipment</p>
                            <p>FreezeSorb® our non-toxic salt-based phase change material, delivers temperature control of -25 to -15ºC to safeguard frozen cargo</p>
                            <ul className="ul-holder">
                                <li>
                                    <h3>Temperature range</h3>
                                    <p>-25 to -15ºC</p>
                                </li>
                                <li>
                                    <h3>Duration</h3>
                                    <p>Up to 48 hours</p>
                                </li>
                                <li>
                                    <h3>Payload size</h3>
                                    <p>5L, 21L, 47L</p>
                                </li>
                                <li>
                                    <h3>Insulation</h3>
                                    <p>EPS (Expanded Polystyrene)</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                


            </div>

            <h1 style={{display: 'none'}}>Become a driver</h1>
            <div id="contact_form_show" className="sonoco_form">
                

                <div className="col-lg-12">
        <div className="bg-white rounded p-5 m-5 mb-0 formenq">
        <h4 className="formtitle">Get Started - Sonoco Thermosafe</h4>
          <form>
            <div className="row g-3">
              <div className="col-12 col-sm-6">
                <input type="text" className="form-control bg-light border-0" onChange={(e)=>setformdata({...formdata,name:e.target.value})} placeholder="Your Name" style={{height: '55px'}} />
              </div>
              <div className="col-12 col-sm-6">
                <input type="email" className="form-control bg-light border-0" onChange={(e)=>setformdata({...formdata,email:e.target.value})} placeholder="Your Email" style={{height: '55px'}} />
              </div>
              <div className="col-12">
                <input type="text" className="form-control bg-light border-0" onChange={(e)=>setformdata({...formdata,phone:e.target.value})}  placeholder="Contact Number" style={{height: '55px'}} />
              </div>
              <div className="col-12">
                <textarea className="form-control bg-light border-0" onChange={(e)=>setformdata({...formdata,message:e.target.value})} rows={5} placeholder="Message" defaultValue={""} />
              </div>
              <div className="col-6">
                <button className="btn btn-primary w-100 py-3 menabtn" onClick={e => handleFormSubmit(e)} type="submit">Send Message</button>
              </div>
            </div>
          </form>
          {resultmsg.length > 0 &&
                    <p style={{marginTop:"1%"}}>{resultmsg}</p>
                }
                {loadingshow?(
              <div style={{textAlign:'center'}}><img src={load} style={{width:'6%'}} /></div>
                ):null}
        </div>
      </div>

                
               
            </div>
            <div style={{display:"inline-block"}}></div>
        </div>
        </section>
  )
}
