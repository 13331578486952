import React from 'react'
import { Helmet } from 'react-helmet'
import '../../css/Custom.css'
import banner from '../../img/banners/15.jfif'
export default function Sourcing() {
  return (
    <section id="cd-intro">
    <Helmet>
            <title>MenaPac | Sourcing</title>
          </Helmet>
            <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center">
                            <div className="business_content">
                           
                                <h1 className="bannertitle" >Sourcing</h1>
                                {/* <p>Search for answers or explore regular help topics below</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="pages_content improvelive" style={{marginBottom:'3%'}}>
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%',marginTop:'2%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content">
              <div className="become_driver im_lives">
                <div className="border_text">
                  <strong>TECHNICAL AND REGULATORY SUPPORT</strong>
            
                  

<p>A global sourcing tradition (Partners Worldwide), allows our company to maintain its edge in providing quality ingredients,
products and service at competitive pricing, whilst steadfastly adhering to strict moral and ethical codes.
</p>
<strong>Please free to contact us for more details about your enquiry</strong>
  </div>
  </div>

  
  </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </section>
  )
}
