import React from 'react'
import { Helmet } from 'react-helmet'
import '../../css/Custom.css'
import banner from '../../img/banners/16.jfif'
export default function Shipping() {

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  return (
    <section id="cd-intro">
    <Helmet>
            <title>MenaPac | Shipping And Logistics</title>
          </Helmet>
            <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center">
                            <div className="business_content">
                           
                                <h1 className="bannertitle" >Shipping & Logistics</h1>
                                {/* <p>Search for answers or explore regular help topics below</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="pages_content improvelive" style={{marginBottom:'3%'}}>
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%',marginTop:'2%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content">
              <div className="become_driver im_lives">
                <div className="border_text">
                <FadeInSection id="1">
                 <h5>Medical products and Pharmaceuticals</h5>
                 <p>Our flexible logistics solutions to pharmacies, hospitals and residential homes meet your increasingly important need for value-added healthcare logistics services during delivery.</p>
 <p>Direct delivery models and in-hospital services are key success factors in this fast growing, including diagnostics, consumables, clinical foods, high volume market, which includes hospital consumables, high-end cosmetics, nutritional or clinical foods, latex gloves and other disposables.</p>
 <p>Services include packaging and repackaging, pre-conditioning, labelling and kitting, returns and stock levels, restacking based on expiration date, and swapping products.</p>
 <p>We are experienced in delivering your products to many different destinations: from pharmacies to retail outlets, as well as direct to patients.</p>
 </FadeInSection>
 <FadeInSection id="2">
 <h5>Specialist healthcare solutions</h5>
 <h6>Biopharma</h6>
 <p>Our innovative supply chain solutions mirror the shift towards product transparency and direct delivery models from traditional distribution models.</p>
 <p>Our diagnostic and clinical trial management, repacking and display configuration, all controlled by our in-house pharmacists.</p>
 <p>Our delivery services eliminate costs and inefficiencies, control tower, and include next-day delivery, direct-to-hospital department deliveries, 24/7 standby service and recall management – all available in condition-controlled environments. </p>
 <p>We work with biologicals, pharmaceutical products (prescription drugs, generics, controlled drugs and over-the-counter (OTC) products), vaccines, active pharmaceutical ingredients (APIs) and clinical trial materials.</p>
 
 </FadeInSection>
 <FadeInSection id="3">
 <h5>Hospitals and care homes</h5>
 <p>Our efficient logistics supply chain solutions improve visibility, shipments integrity, trach & trace, and reduce costs, which helps improve patient care in hospitals, clinics, nursing homes and, increasingly, at home with the support of e-health solutions.</p>
<p>Our services include direct delivery to hospitals, residential, sterile goods logistics, drug management, operational procurement, laboratory logistics management of medical goods, disposal of hazardous waste, stock reduction programmes, inventory management, external and internal distribution, replenishment at ward level and much more.</p>

</FadeInSection>
 <FadeInSection id="4">
<h5>Pharma cold chains</h5>
 <p>Our state of the art temperature monitoring system ensures that your pharma supply chains integrity of your product is preserved all the way to the user. This can be critical for pharmacists because it's their guarantee that the medicine they are dispensing meets the required health standards while patients need the comfort and reassurance that the medicine they are taking is safe.</p>
<p>Traditionally, transporting your pharmaceuticals has relied heavily on packaging to keep product safe and within prescribed temperature parameters being -15 to -25°C, 2-8°C, or 22-25°C. However, logistics have become more complex over time as safety demands as well as distance to deliver have increased. Good Distribution Practice (GDP) adds yet more layers to the complexity. GDP requires medicines to be obtained from a licensed supply chain and then stored, transported and handled under stipulated conditions.</p>
<p>We tailor solutions to your unique handling and transportation requirements to ensure your requirements are met. Our medical services include a comprehensive network of qualified and compliant cold chain facilities and we are committed to reliable and exceptional service with every shipment.</p>
</FadeInSection>
 </div>
  </div>

  
  </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </section>
  )
}
