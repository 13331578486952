//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../../css/Sonoco.css';
import banner from '../../img/banners/23.jfif'
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-bootstrap';

import api from '../../img/products/api.jfif'
import api2 from '../../img/products/api2.jfif'

export default function API() {
  
    const [type, settype] = useState('product')
function settabtype(val){
    settype(val)
}
  return (

    <section id="cd-intro">
<Helmet>
        <title>MenaPac | API</title>
      </Helmet>
        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'bottom' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="bannertitle" >API</h1>
                            {/* <p>Search for answers or explore regular help topics below</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="container help mt-5 maincontent">

            <div className='row'>
                <div className="col-md-5 n-slider" style={{paddingLeft:'0px'}}>
                <Carousel>
   
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={api2}
        alt="Second slide"
        />
        <Carousel.Caption>
        
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={api}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    
   
</Carousel>
              
                </div>
              
 <div className="col-md-7 greybg tabsec" style={{paddingRight:'0px'}}>
    <p> All active pharmaceutical ingredients have FDA, CEP , GMP, WHGMP and all of it USP type</p>
    <ul>
        <li> Amantadine HCl [CP2015]</li>
        <li> Metoprolol Succinate [USP/EP/CP]</li>
        <li>Bupropion Hydrochloride [USP]</li>
        <li>Levofloxacin Hemihydrate [CP2015]</li>
    </ul>
   

                </div>

            </div>

            
        
            <div style={{display:"inline-block"}}></div>
        </div>
      
        </section>
  )
}
