import React from 'react'
import { Helmet } from 'react-helmet'
import '../../css/Custom.css'
import banner from '../../img/banners/19.jfif'

export default function Pharmaceutical_distribution() {

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  return (
    <section id="cd-intro">
    <Helmet>
            <title>MenaPac | Pharmaceutical Distribution</title>
          </Helmet>
            <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center">
                            <div className="business_content">
                           
                                <h1 className="bannertitle" >Pharmaceutical Distribution</h1>
                                {/* <p>Search for answers or explore regular help topics below</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="pages_content improvelive" style={{marginBottom:'3%'}}>
            <div className="container">
                <div className="row">
                    <div id="primary" className="content-area" style={{float: 'left', width: '100%',marginTop:'2%'}}>
                        <main id="main" className="site-main" role="main">
                            <div className="entry-content">
                                <div className="become_driver im_lives">
                                    <p><strong>Menapac Healthcare is a leading MENA pharmaceutical distributor</strong> with unique infrastructure capabilities to deliver best in class healthcare to both patients and medical providers.</p>
                                    <p>We specialize in helping companies bringing, registering, storing, and selling pharmaceutical drugs into the complex pharmaceutical regulations of the medical and pharmaceutical sector, particularly in the United Arab Emirates, Saudi Arabia, Kuwait, Oman, Qatar, and Bahrain.</p>
                                    <p>Menapac innovation provides a diverse portfolio of pharmaceutical and consumer healthcare products, positioning itself at the forefront of holistic healthcare and wellness industry, as well as enabling people to live healthier lives.</p>
                                    <p>Through our comprehensive distribution network including hospitals, clinics, pharmacies, specialty centres and many others, across both private and government sectors, we maintain our deep commitment to building lasting relationships in our local communities.</p>
                                </div>                
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
