import React from 'react'
import { Helmet } from 'react-helmet'
import '../../css/Custom.css'
import banner from '../../img/banners/19.jfif'
export default function Customise_solution() {
  return (
    <section id="cd-intro">
    <Helmet>
            <title>MenaPac | Customized Packaging Solution </title>
          </Helmet>
            <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center">
                            <div className="business_content">
                           
                                <h1 className="bannertitle" >Customized Packaging Solution</h1>
                                {/* <p>Search for answers or explore regular help topics below</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="pages_content improvelive" style={{marginBottom:'3%'}}>
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%',marginTop:'2%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content">
              <div className="become_driver im_lives">
                <div className="border_text">
                  <p>A hands-on Packaging Consultancy specialising in Packaging Cost Reduction Packaging Innovation, Sustainability and Automation projects.</p>
  <strong>Custom packaging your customers will love</strong>
  <p>Explore, design and order environmentally-conscious packaging.</p>
  <h5>CUSTOMISED PACKAGING SOLUTIONS |FEATURE & BENEFITS</h5>
  <div>
    <ul>
        <li> Highly-durable and lightweight</li>
        <li> Made from international-grade plastic resin sheets</li>
        <li> Versatile and flexible specifications based on custom requirement</li>
        <li> Provision of physical prototype for design & dimension approval</li>
        <li> On-demand printing solutions for packaging material also available</li>
    </ul>
  </div>
  </div>
  </div>

  
  </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </section>
  )
}
